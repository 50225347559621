import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiamondImage = styled.div`
  position: relative;
  margin: 20px 0 40px;
  text-align: center;
`

const StyledDiamondImageHolder = styled.figure`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 150px;
  width: 150px;
  overflow: hidden;
  position: relative;
  border: 1px solid grey;
  transform: rotate(-45deg);
  transform-origin: center center;
`

const Image = styled.img`
  object-fit: cover;
  width: 140%;
  height: 140%;
  transform: rotate(45deg);
  transform-origin: center center;
`

const DiamondImage = ({ image, alt }) => (
  <StyledDiamondImage>
    <StyledDiamondImageHolder>
      <Image src={image} alt={alt} />
    </StyledDiamondImageHolder>
  </StyledDiamondImage>
)

DiamondImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

export default DiamondImage
