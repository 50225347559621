import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Subheader from '../components/Subheader/Subheader'
import Paragraph from '../components/Paragraph/Paragraph'
import Button from '../components/Button/Button'
import Container from '../components/Container/Container'
import ColoredSection from '../components/ColoredSection/ColoredSection'
import Slider from '../components/Slider/Slider'

import Image1 from '../assets/images/zwyzka-teleskopowa-helios.jpg'
import Image2 from '../assets/images/zwyzka-teleskopowa-agata-meble-szyldy-reklamowe.jpg'
import Image3 from '../assets/images/zwyzka-teleskopowa-wycinka-drzew.jpg'
// import Image4 from "../assets/images/pomoc-drogowa.jpeg";
// import Image5 from "../assets/images/pomoc-drogowa-holowanie.jpeg";
// import Image6 from "../assets/images/podnosnik-koszowy-rzeszow.jpg";
// import Image7 from "../assets/images/podnosnik-koszowy-podkarpackie.jpg";
// import Image8 from "../assets/images/nozycowka.jpg";

import Icon1 from '../assets/svg/icon1.svg'
import Icon2 from '../assets/svg/icon2.svg'
import Icon9 from '../assets/svg/icon9.svg'
import Icon18 from '../assets/svg/icon18.svg'
import Icon20 from '../assets/svg/icon20.svg'

import sliderImage1 from '../assets/images/sprzet/socage-da20.jpg'
import sliderImage2 from '../assets/images/sprzet/bizzocchi-autel-195cl.jpg'
import sliderImage3 from '../assets/images/sprzet/podnosnik-30m.jpg'
import sliderImage4 from '../assets/images/sprzet/haulotte-compact-12.jpg'
import sliderImage5 from '../assets/images/sprzet/grove-amz51e.jpg'
import sliderImage6 from '../assets/images/sprzet/socage-da22.jpg'
import sliderImage7 from '../assets/images/sprzet/socage-da328.jpg'
import sliderImage8 from '../assets/images/sprzet/scania.jpg'

import Mapa from '../assets/images/mapa.png'

const services = [
  {
    icon: `${Icon1}`,
    name: 'Zwyżka - Podnośnik koszowy',
    desc:
      'Oferujemy wynajem zwyżki koszowej wraz z operatorem do wszelakich prac budowlanych i ogrodowych. Sprawnie, szybko i elastycznie zrealizujemy każde zlecenie. Podejmiemy się prac na wysokościach, takich jak: wycinka drzew, czyszczenie rynien, montowanie bilboardów/reklam, czy usuwanie zagrażających przechodniom sopli i śniegu.',
    link: '/uslugi',
  },
  {
    icon: `${Icon2}`,
    name: 'Podnośnik nożycowy - Nożycówka',
    desc:
      'Doskonałe rozwiązanie w warunkach, które ze względu na bezpieczeństwo nie pozwalają użyć podnośnika koszowego. Nasze doświadczenie pozwala nam rozsądnie wycenić daną usługę, a następnie sprawnie ją zrealizować. Zaufało nam już wielu klientów indywidualnych i firm.',
    link: '/uslugi',
  },
  {
    icon: `${Icon20}`,
    name: 'Holowanie - Pomoc drogowa',
    desc:
      'W naszej flocie aut i urządzeń posiadamy również lawetę. Pomożemy w różnorakich opresjach drogowych. Pomożemy po kolizji drogowej. Wypożyczymy lawetę lub przyczepę. Dowieziemy paliwo.',
    link: '/uslugi',
  },
  {
    icon: `${Icon18}`,
    name: 'Hydraulika',
    desc:
      'Oprócz usług związanych z pracami wysokościowymi, oraz holowaniem - nasi pracownicy są w stanie również pomóc w pracach hydraulicznych. Dojeżdżamy na terenie północnej części podkarpacia do awarii kanalizacyjnych i hydraulicznych.',
    link: '/uslugi',
  },
  {
    icon: `${Icon9}`,
    name: 'Wynajem maszyn budowlanych',
    desc:
      'Posiadamy gamę maszyn i urządzeń do wielu zastosowań budowlanych, także tych specjalistycznych. Dostępne są sprzęty o różnych parametrach technicznych, co pozwala na dobór odpowiedniego urządzenia do wymagań danej inwestycji.',
    link: '/uslugi',
  },
]

const equipment = [
  {
    image: `${sliderImage7}`,
    imageAlt: 'Socage DA-328',
    name: 'Socage DA-328',
    desc: false,
    link: '/sprzet/',
  },
  {
    image: `${sliderImage1}`,
    imageAlt: 'Socage DA-20',
    name: 'Socage DA-20',
    desc: false,
    link: '/sprzet/',
  },
  {
    image: `${sliderImage6}`,
    imageAlt: 'Socage DA-22',
    name: 'Socage DA-22',
    desc: false,
    link: '/sprzet/',
  },
  // {
  //   image: `${sliderImage2}`,
  //   imageAlt: 'Bizzocchi Autel 195CL',
  //   name: 'Bizzocchi Autel 195CL',
  //   desc: false,
  //   link: '/sprzet/',
  // },
  // {
  //   image: `${sliderImage3}`,
  //   imageAlt: 'Podnośnik koszowy 30m',
  //   name: 'Podnośnik koszowy 30m',
  //   desc: false,
  //   link: '/sprzet/',
  // },
  {
    image: `${sliderImage4}`,
    imageAlt: 'Haulotte Compact 12',
    name: 'Haulotte Compact 12',
    desc: false,
    link: '/sprzet/',
  },
  {
    image: `${sliderImage5}`,
    imageAlt: 'Grove AMZ51E',
    name: 'Grove AMZ51E',
    desc: false,
    link: '/sprzet/',
  },
  {
    image: `${sliderImage8}`,
    imageAlt: 'Laweta Scania',
    name: 'Laweta Scania',
    desc: false,
    link: '/sprzet/',
  },
]

const IndexPage = () => (
  <Layout>
    <SEO
      title="Kamel | Zwyżka, podnośnik koszowy, nożycowy - wynajem | Rzeszów, Stalowa Wola, Tarnobrzeg"
      keywords={[
        'zwyżka',
        'wynajem',
        'podnośnik koszowy',
        'hydraulika',
        'pomoc drogowa',
        'holowanie',
        'autohol',
        'maszyny budowlane',
        'bojanów',
        'stalowa wola',
        'nowa dęba',
        'tarnobrzeg',
        'sandomierz',
        'podkarpacie',
        'rzeszów',
        'janów lubelski',
      ]}
    />
    <Hero image={Image1}>
      <h1>Profesjonalne usługi zwyżką i podnośnikiem na terenie podkarpacia</h1>
      <Subheader>Dla firm i klientów indywidualnych</Subheader>
      <Button as={Link} to="/uslugi/">
        Zobacz więcej
      </Button>
    </Hero>

    <Header isBig>Nasz oferta</Header>
    <Slider data={services} sliderType="services" />

    <ColoredSection isUp image={Image2}>
      <Header isBig>Profesjonalizm i doświadczenie</Header>
      <Container columns={2}>
        <Paragraph>
          Oferujemy wynajem zwyżki koszowej wraz z operatorem do wszelakich prac
          budowlanych i ogrodowych. Sprawnie, szybko i elastycznie zrealizujemy
          każde zlecenie. Podejmiemy się prac na wysokościach, takich jak:
          wycinka drzew, czyszczenie rynien, montowanie bilboardów/reklam, czy
          usuwanie zagrażających przechodniom sopli i śniegu.
        </Paragraph>
        <Paragraph>
          Posiadamy podnośniki koszowe o zasięgu 16m, 17m, 20m i 30m. Nasz
          sprzęt jest obłśugiwany przez wyszkolonych i doświadczonych
          operatorów. Do każdego zlecenia podchodzimy indywidualnie.
        </Paragraph>
        <Paragraph>
          Zwyżki, oraz podnośniki można wykorzystać do wielu prac. Nasze
          doświadczenie pozwala nam rozsądnie wycenić daną usługę, a następnie
          sprawnie ją zrealizować. Zaufało nam już wielu klientów indywidualnych
          i firm z terenu województw podkarpackiego, świetokrzyskiego i
          lubelskiego.
        </Paragraph>
        <Paragraph>
          Centrala naszej firmy znajduje się w Bojanowie, który usytuowany jest
          pomiędzy miejscowościami: Stalowa Wola, Tarnobrzeg, Sandomierz, Nisko,
          Nowa Dęba, Kolbuszowa. Nasze usługi świadczymy jednak na znacznie
          większym obszarze. Zadzwoń aby dowiedzieć się ile może kosztować dana
          usługa w Twojej lokalizacji.
        </Paragraph>
      </Container>
    </ColoredSection>

    <ColoredSection image={Image3}>
      <Header isBig>Nasz sprzęt</Header>
      <Slider data={equipment} sliderType="equipment" />
    </ColoredSection>

    <Header isBig>Zadzwoń do nas po wycenę</Header>
    <Container columns={2}>
      <div>
        <Paragraph>
          W celu omówienia szczegółów realizacji usługi prosimy o kontakt
          telefoniczny. W trakcie rozmowy ustalimy szczegółowy plan, termin oraz
          cenę zlecenia.
        </Paragraph>
        <Paragraph>
          Centrala naszej firmy znajduje się w Bojanowie, który usytuowany jest
          pomiędzy miejscowościami: Stalowa Wola, Tarnobrzeg, Sandomierz, Nisko,
          Nowa Dęba, Kolbuszowa. Nasze usługi świadczymy jednak na znacznie
          większym obszarze.
        </Paragraph>
      </div>
      <div>
        <img
          src={Mapa}
          alt="Mapa Polski z zaznaczonymi miejscowościami: Rzeszów, Stalowa Wola, Tarnobrzeg, Mielec, Sandomierz, Nisko, Nowa Dęba..."
        />
      </div>
    </Container>
  </Layout>
)

export default IndexPage
