import React, { Component } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from 'gatsby'

import Button from '../Button/Button'
import DiamondImage from '../DiamondImage/DiamondImage'

const SliderWrapper = styled.section`
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  width: 100vw;
  height: ${({ sliderType }) =>
    sliderType === 'services' ? '850px' : '450px'};
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => theme.media.desktop} {
    overflow: hidden;
    width: 100%;
    height: auto;
  }
`

const SliderList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 0;
  width: calc(270px * ${props => props.elmentsLength});
  height: 100%;
  list-style: none;

  ${({ theme }) => theme.media.desktop} {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    padding: 0 90px;
    width: 100%;
    max-width: 1920px;
  }
`

const SliderElement = styled.li`
    display inline-block;
    width: 250px;
    text-align: center;
    ${({ sliderType }) => sliderType === 'services' && 'padding: 0 20px;'}

    ${({ theme }) => theme.media.desktop} {
        margin: 0 0 100px;
        width: 100%;
    }
`

const StyledIcon = styled(SVG)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  svg {
    width: 100%;
    height: 100%;
  }
`

class Slider extends Component {
  render() {
    const elements = this.props.data
    const sliderType = this.props.sliderType

    return (
      <SliderWrapper sliderType={sliderType}>
        <SliderList elmentsLength={elements.length}>
          {elements.map((element, index) => (
            <SliderElement key={index} sliderType={sliderType}>
              {element.icon && <StyledIcon src={element.icon} />}
              {element.image && (
                <DiamondImage image={element.image} alt={element.imageAlt} />
              )}
              <h3>{element.name}</h3>
              {element.desc && <p>{element.desc}</p>}
              {element.link && (
                <Button as={Link} to={element.link}>
                  Więcej
                </Button>
              )}
            </SliderElement>
          ))}
        </SliderList>
      </SliderWrapper>
    )
  }
}

export default Slider
