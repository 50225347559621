import React, { Component } from 'react'
import styled from 'styled-components'

const ColoredSectionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: ${({ theme }) => theme.media.max};
  margin: 0 auto;
  padding: ${props => (props.isUp ? '25vh 40px 10vh' : '10vh 40px 25vh')};
  background: center center no-repeat;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${props => props.image});
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ::before {
    z-index: 2;
    position: absolute;
    top: ${props => (props.isUp ? 'auto' : '100%')};
    bottom: ${props => (props.isUp ? '100%' : 'auto')};
    left: 0;
    width: 110%;
    height: 80%;
    content: '';
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: rotate(${props => (props.isUp ? '10deg' : '-10deg')});
    transform-origin: ${props => (props.isUp ? 'bottom left' : 'top left')};
  }

  > * {
    position: relative;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: ${props => (props.isUp ? '30vh 20vw 15vh' : '15vh 40px 30vh')};
  }
`

const ColoredSectionMask = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.isUp
      ? ({ theme }) => theme.colors.primary
      : ({ theme }) => theme.colors.dark};
  opacity: ${props => (props.isUp ? '.8' : '.9')};
`

const ColoredSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  max-width: 1200px;
  text-align: left;

  img {
    margin: 0 0 20px;
    max-width: 100%;
  }

  p {
    margin-top: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-column-gap: 30px;
  }
`

class ColoredSection extends Component {
  render() {
    return (
      <ColoredSectionWrapper isUp={this.props.isUp} image={this.props.image}>
        <ColoredSectionMask isUp={this.props.isUp} />
        {this.props.children}
      </ColoredSectionWrapper>
    )
  }
}

export default ColoredSection
